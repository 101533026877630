import React from 'react'
import { Link } from 'gatsby'

import styled from 'styled-components/macro'
import GlobalStyles from '../GlobalStyles'
import ProfilePic from '../img/Rebecca_round.png'

export default function Linkinbio() {
  return (
    <>
      <GlobalStyles />
      <div pageTitle="Links in Bio!">
        <main>
          <ContentContainer>
            <ProfilePicStyled
              src={ProfilePic}
              loading="lazy"
              alt=""
              width="150px"
              height="150px"
            />
            <h2 className="highlight italic">@soulcoding</h2>

            <Link to="/blog/">
              <div>&raquo;to my blog&laquo;</div>
            </Link>
            <Link to="/">
              <div className="highlighted">&raquo;to my website&laquo;</div>
            </Link>
            <Link to="/contact/">
              <div className="highlighted">&raquo;drop me a line&laquo;</div>
            </Link>
            <a
              href="https://www.linkedin.com/in/rebecca-frey-8205263a/"
              rel="noreferrer"
              title="Rebecca's LinkedIn profile"
            >
              <div>&raquo;to my linkedin profile&laquo;</div>
            </a>
            <a
              href="https://www.xing.com/profile/Rebecca_Frey5/"
              rel="noreferrer"
              title="Rebecca's XING profile"
            >
              <div>&raquo;to my xing profile&laquo;</div>
            </a>
            <a
              href="https://open.spotify.com/playlist/5TI17nwAvnDC3wc7fx2NwO?si=1fuWtdGJRfK1wqDBO9JEfA"
              rel="noreferrer"
              title="Christmas playlist"
            >
              <div>&raquo;to my Christmas playlist&laquo;</div>
            </a>
            <a
              href="https://bit.ly/ich-mag-kekse"
              rel="noreferrer"
              title="cookie recipes"
            >
              <div>&raquo;get cookie recipes&laquo;</div>
            </a>
          </ContentContainer>
        </main>
      </div>
    </>
  )
}

const ContentContainer = styled.div`
  display: grid;
  place-items: center;
  padding-top: 24px;
  text-transform: lowercase;

  > h2 {
    color: var(--primary-dark);
    font-size: 20px;
    font-weight: lighter;
  }

  > a {
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-dark);
    }
  }

  a > div {
    display: block;
    border: 2px solid var(--primary-dark);
    border-radius: 12px;
    box-shadow: -4px 5px 8px 0 rgba(67, 86, 100, 0.12),
      -1px 2px 4px 0 rgba(67, 86, 100, 0.1);
    font-size: 16px;
    font-weight: lighter;
    letter-spacing: 2px;
    margin: 12px auto;
    padding: 12px 12px;
    text-align: center;
    width: 80vw;

    &:hover,
    &:focus,
    &:active {
      border: 2px solid var(--primary-light);
    }
  }

  .highlighted {
    background-color: var(--primary-light);
  }
`
const ProfilePicStyled = styled.img`
  margin-bottom: 10px;
  width: 100px;
  height: auto;
`
